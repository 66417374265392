<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import { addYears, formatWithOptions } from 'date-fns/fp'
//import { eo } from 'date-fns/locale'
//import image from "@/assets/images/users/user.png";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Historiques des locations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Historique sur RMobility",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Location sur RMobility",
          active: true,
        },
      ],

      transaction:{
        total:"",
        echouee:"",
        reussie:""
      },
       totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100,250,500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      ordersData: [
      ],
      fields: [
        { key: "marque", sortable: true, label: "Marque" },
        //{ key: "model", sortable: true, label: "Modèle" },
        { key: "immatri", label: "Immat." },
        //{ key: "type", sortable: true, label: "Type" },
        { key: "message", sortable: true },
        { key: "montant", sortable: true },
        { key: "dateReservation", sortable: true , label: "Date reserv." },
        { key: "dateDepart", sortable: true , label: "Départ"},
        { key: "dateRetour", sortable: true , label: "Retour"},

      ],
      transactionData:[],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
    };
  },

  async mounted(){
    const historiqueLocation = await apiRequest(
      "GET",
      "admin/entreprise/reservation",
      undefined,
      false
    );

    console.log('historiqueLocation:', historiqueLocation.data)

    if(historiqueLocation && historiqueLocation.data){
      const locations  = historiqueLocation.data.map((location)=>{
       return{
         dateReservation : new Date(location.dateReservation).toLocaleDateString("fr-FR"),
         dateDepart : new Date(location.dateDepart).toLocaleDateString("fr-FR"),
         dateRetour : new Date(location.dateRetour).toLocaleDateString("fr-FR"),
         montant: location.fraisReservation,
         message: location.messageLaisser,
         marque: location.vehicule.marque,
         model: location.vehicule.model,
         immatri: location.vehicule.immatriculation,
         type: location.vehicule.types,
      }
     });
     this.ordersData = locations;
    }
  },

  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style></style>
